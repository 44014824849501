/* Default CSS start */
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-weight: 400;
  color: #eeeeee;
  transition: 0.7s ease-in-out;
  background-color: #121212;
}
a,
li,
button,
ul {
  line-height: normal;
  text-decoration: none;
}
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
  color: #dbb3fe;
}
/* Default CSS End*/

/* Spotify Start*/
.spotify-cont{
  width: 100vw;
  height: 100vh;
  position: relative;
  background-position: center;
}
.blur{
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: relative;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.spotify-track {
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #181818;
  border-radius: 10px;
  width: 300px;
  padding: 10px; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.song-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.song-title {
  font-size: large;
  font-weight: 800;
}
.song-artist {
  font-weight: 500;
  font-size: small;
  margin-bottom: 4px;
}
.song-img {
  display: contents;
  float: left;
  margin-top: 5px;
}
.song-img img {
  width: 100%;
  border-radius: 10px;
}